.card {
  justify-content: center;
  align-items: center;
  padding: 10%;
}

.aminities_card {
  display: flex;
  align-items: center;
  height: 221px !important ;
  width: 90%;
}
.card-amenity-wrapper {
  margin-top: 80px;
}
.card-img-top {
  width: 70px !important;
  height: 70px;
}

.know_btn {
  width: 250px;
  height: 60px;
  border: 0;
  border-radius: 8px;
  color: #fff;
  background: linear-gradient(
    45deg,
    #e6b0a2 0%,
    #c39ea0 33.33%,
    #b9778f 66.67%,
    #9c640c 100%
  );
  margin-top: 60px;
}

.amenities_title {
  font-size: 50px;
  font-weight: 500;
  color: #ffffff;
}
@media only screen and (max-width: 768px){
  .amenities_title{
    font-size: 40px;
  }
}
@media only screen and (max-width: 400px){
  .amenities_title{
    font-size: 30px;
  }
}
.card_h5 {
  padding: 5px;
  /* font-weight: 700; */
}
@media only screen and (max-width: 768px) {
  .aminities_card {
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 122.39px !important ; */
    width: 90%;
  }
  .card-amenity-wrapper > div {
    margin-left: 16.66667%;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .aminities_card {
    height: 221px !important ;
    width: 90%;
  }
}

@media (max-width: 1199px) and (min-width: 1024px) {
  .aminities_card {
    height: 180px !important ;
    width: 90%;
  }
}
@media (max-width: 1023px) and (min-width: 769px) {
  .aminities_card {
    height: 170px !important ;
    width: 90%;
  }
}

@media (max-width: 425px) {
  .aminities_card {
    height: 122.39px !important ;
    width: 90%;
  }
}

@media (max-width: 425px) and (min-width: 300px) {
  .aminities_card {
    height: 150px !important ;
    width: 90%;
  }
}
