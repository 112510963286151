.modal-header {
  background-color: #151b54;
}

.modal-form input {
  height: 50px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: var(--primary-color);
}

.modal-form select {
  height: 50px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: var(--primary-color);
}
.phone-input input {
  height: 50px !important;
  border: 2px solid var(--primary-color) !important;
  border-radius: 5px !important;
  color: var(--primary-color) !important;
  width: 100% !important;
}
.modal-title {
  display: flex;
  justify-content: flex-start;
  margin: auto;
}

.form_submit_btn {
  width: 200px;
  height: 45px;
  border: 0;
  border-radius: 8px;
  color: #fff;
  background: linear-gradient(
    45deg,
    #e6b0a2 0%,
    #c39ea0 33.33%,
    #b9778f 66.67%,
    #9c640c 100%
  );
}

.modal-header .btn-close {
  background-color: white;
  color: white;
}
