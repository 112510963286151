@import url("https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

p {
  font-family: "Poppins" !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins" !important;
}
div {
  font-family: "Poppins" !important;
}
/* Header */
.offcanvas {
  width: 250px !important;
}

.header-routes-canvas {
  padding-left: 0;
}
.header-routes-canvas a {
  list-style: none;
  padding: 20px 10px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  color: #151b54;
}
.header-routes-canvas a {
  text-decoration: none;
}
.header-routes a {
  text-decoration: none;
  color: #5d5e6a;
}

.header-routes {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
}
.header-routes a {
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header {
  height: 70px;
  display: flex;
  padding: 0 30px;
  background-color: #ffffff;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px; */
  background-color: #fff;
  position: sticky;
  z-index: 1;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
}

.offcanvas-header .btn-close {
  margin: 0 !important;
  border-radius: 50%;
  border: 1px solid #151b54;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
}

.custom-para {
  width: 50%;
}

.custom-img {
  width: 100%;
  background-color: #151b54;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
}

.carousel-caption {
  position: absolute;
  right: 18% !important;
  bottom: 20.25rem !important;
  left: 2% !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

@media (max-width: 568px) {
  .custom-img {
    width: 100%;
    height: 516px;
    object-fit: cover;
  }

  .custom-para {
    width: 100%;
  }
}

/* welcom warrior section */

.main-warrior-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.warrior-header {
  font-size: 101.15px;
  font-weight: 500;
  line-height: 101.15px;
  color: #181d24;
}
.warrior-header1 {
  font-size: 64px;
  font-weight: 500;
  line-height: 64px;
  color: #181d24;
}
.fw-500{
  font-weight: 500;
}
.line-height-sm {
  word-spacing: 1px !important;
}
.warrior-sub-text {
  font-size: 16.39px;
  font-weight: 400;
  text-align: left;
  color: #181d24;
  gap: 12px;
}

.warrior-img-container {
  display: flex;
  gap: 56px;
  margin-top: 30px;
}

.btn-know-more {
  width: 269px;
  height: 59px;
  padding: 14px;
  border: 0;
  border-radius: 8px;
  background: #151b54;
  color: #fff;
}

.bedroom-img-1 {
  width: 100%;
}

@media (min-width: 1600px) {
  .warrior-header {
    font-size: 86px;
    line-height: 73.57px;
  }
  .warrior-sub-text {
    width: 570px;
    font-size: 24.39px;
    line-height: 37.14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .warrior-sub-text {
    width: 350px;
  }
  .warrior-img-container {
    gap: 30px;
  }
}

/* ABOUT SECTION CSS  */
.about-title {
  font-family: Almarai;
  font-size: 45px;
  font-weight: 700;
  color: #ffffff;
}
.about-description {
  margin-top: 60px;
  color: #ffffff;
  font-family: Almarai;
  font-size: 28px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: center;
}
.img-fluid-icon {
  margin-bottom: 30px;
}
/* .img-fluid {
  height: 900px !important;
} */
/* ENDS HERE  */

/* .warrior-main-div {
  margin: 138px 98px 132px 98px !important;
} */

@media (max-width: 568px) {
  .warrior-header1 {
    font-size: 50px;
    font-weight: 500;
    line-height: 64px;
    color: #181d24;
  }

  .warrior-header {
    font-size: 36px;
    line-height: 38.2px;
  }
  .img-fluid {
    height: 700px !important;
  }
  .warrior-img-container {
    gap: 57px;
  }
  
  .warrior-main-div {
    margin: 45px !important;
  }
  .warrior-img {
    height: 74px;
  }

  .btn-know-more {
    width: 156px;
    height: 40px;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .bedroom-img-1 {
    width: 100%;
    height: auto;
  }
  .img-fluid {
    height: 700px !important;
  }
  .warrior-right-side-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .about-title {
    font-size: 36px;
    color: #ffffff;
    margin-bottom: 30px;
    margin-top: 24px;
  }
  .warrior-main-div {
    margin: 45px !important;
  }
  .about-description {
    margin-top: unset;
    font-size: 15px;
    color: #ffffff;
  }
  .img-fluid-icon {
    width: 60px;
    height: 80px;
    margin-bottom: unset;
  }
  .about-responsive {
    width: 100%;
  }
  .about-icon-responsive {
    margin-bottom: 24px;
  }
}

/* Custom styles */
.brand-logo {
  width: 200px;
  height: 80px;
  padding:20px 8px;
}

.btn-gradient {
  color: #ffffff !important;
  background: linear-gradient(to top, #cf5a01 0%, #da9c29 100%);
  border: 0;
  outline: none;
}

.btn-border-radius {
  border-radius: 5px !important;
}

.h480 {
  height: 480px;
}

.btn-outline-dark {
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
}

.map-arrow-img{
  height: 35px;
  width: 35px;
}