.sec_title {
  font-family: Almarai;
  font-size: 64px;
  font-weight: 700;
  color: #000000;
  line-height: 1;
}
.section-7 {
  background: var(--primary-color);
  margin-top: 30px;
  color: var(--secondary-color);
}

.design .card-box-img {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.8);
  opacity: 0.9;
  perspective: 70%;
}

.design .card-box-img img {
  max-height: 70vh;
  border: 3px solid var(--color-white);
}

.slick-center {
  opacity: 1;
}

.design .slick-center .card-box-img {
  border: 5px solid var(--primary-color-dark);
  transform: scale(1);
  opacity: 1;
}

.design .slick-next {
  right: 50px !important;
  border: 1px solid #ebf9f7ba !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  z-index: 2 !important;
  padding-left: 1px !important;
  padding-top: 4px !important;
}

.design .slick-prev {
  left: 50px !important;
  border: 1px solid #ebf9f7ba !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  z-index: 2 !important;
  padding-left: 1px !important;
  padding-top: 4px !important;
}
.design .slick-dots li button:before {
  font-size: 8px !important;
  color: transparent !important;
  top: 10px !important;
  border: 3px solid var(--secondary-color) !important;
  border-radius: 50% !important;
}
.btn-know-more-urban {
  width: 250px;
  height: 60px;
  padding: 14px;
  border: 0;
  border-radius: 8px;
  background: #151b54;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .sec_title {
    font-size: 36px;
  }
}
@media (max-width: 568px) {
  .section-7 {
    margin-top: 10px;
  }
}
