.footer {
  color: #151b54;
  background-color: #f7f7f7;
}
.a {
  color: #ffffff;
}
.footer-logo {
  width: 100px;
  height: 100px;
}
/* Nav icons */
.nav-icon div{
  display: block;
  height: 4px;
  border-radius: 4px;
  width: 40px;
  background-color: #000;
  margin-bottom: 5px;
}
.nav-icon .line-2{
  width: 25px;
}