.home-img {
  background-color: #151b54;
  border: 1px solid #151b54;
  /* height: 100vh; */
}
.home-window-img {
  height: 60vh;
  object-fit: contain;
}

.home-img-wrap {
  left: 15% !important;
  top: 68% !important;
}

.home-img-wrap-2 {
  left: 15% !important;
  top: 75% !important;
}

.home-img-mobile-wrap {
  left: 19% !important;
  top: 41% !important;
}

.home-second-slider {
  left: 18% !important;
  top: 31% !important;
}

.home-sec-1 .slick-next {
  right: 30px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
  top: 45%;
}
.home-sec-1 .slick-prev {
  left: 12px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 2;
  padding-left: 1px !important;
  padding-top: 4px !important;
  top: 45%;
}

.slick-prev:before {
  content: none !important;
}

.slick-next:before {
  content: none !important;
}

/* .home-sec-1 {
  height: calc(100vh - -42px);
} */
.home-sec-1 .home-img img {
  /* height: calc(100vh - -60px); */
  width: 100%;
  border: 1px solid #151b54;
}

/* section 2 */

.section-2 {
  flex-direction: column;
}

.home-sec-2 {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home_boxes .sec-2-1 {
  height: 350px;
  display: block;
  margin: -90px 10px 80px !important;
  border-radius: 0;
  width: 300px;
  padding: 15px;
  border: 5px solid #fff;
  box-shadow: 1px 2px 20px #eee;
}
.home_boxes h5 {
  margin-top: 15px;
  font-size: 20px !important;
  color: #525460;
}
.home_boxes p {
  font-size: 13px !important;
  color: #000000;
}

.slider-section-content {
  color: #ffffff;
  /* font-size: 26px; */
}
.slider-section-content-1 {
  font-family: Almarai;
  font-size: 64px;
  font-weight: 800;
  line-height: 87.13px;
  text-align: left;
}

.slider-section-content-2 {
  font-family: Almarai;
  font-size: 30px;
  font-weight: 300;
  line-height: 33.28px;
  text-align: left;
}
.slider-section-button-wrapper {
  display: flex;
  align-items: center;
  width: 437px;
  height: 60.11px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
  margin-top: 100px;
}

.slider-section-button {
  font-family: Almarai;
  font-size: 24px;
  font-weight: 700;
  line-height: 50.47px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #151b54;
  border: unset;
  cursor: pointer;
}
.slider-section-mobile-button {
  font-family: Almarai;
  font-size: 15px;
  font-weight: 700;
  line-height: 50.47px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #151b54;
  border: unset;
}
.marker {
  width: 27.23px !important;
  height: 33.81px !important;
}

.slider-img-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.slider-section-2-button-wrapper {
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 242.98px; */
  height: 50px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
}

.slider-section-1-mobile-button-wrapper d-none {
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  gap: 10px;
  margin-left: 25px;
}
.slider-section-3-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
  margin-top: 30px;
}
.slider-section-3 {
  padding-left: 97px;
  padding-top: 30px;
}
.slider-section-button-wrapper-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 259px;
  height: 35px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
}

.slider-section-content-1-responsive {
  font-family: Almarai;
  font-size: 40px;
  font-weight: 800;
  line-height: 44.38px;
  text-align: left;
}
.slider-section-content-2-responsive {
  font-family: Almarai;
  font-size: 18px;
  font-weight: 300;
}
.slider-section-button-responsive {
  font-family: Almarai;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #151b54;
}
.marker-responsive {
  height: 15px !important;
  width: 15px !important;
}

.slider-section-2-button-wrapper-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
  margin-top: 20px;
}
.slider-section-content-3-responsive {
  font-family: Almarai;
  font-size: 40px !important;
  font-weight: 800;
}
.slider-section-3-button-wrapper-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  gap: 10px;
}
@media only screen and (max-width: 768px) {
  .home-slider-resp {
    width: 100% !important;
    height: 50vh;
  }
  /* .home-img {
    height: 100vh;
  } */
  /* .home-sec-1 {
    height: 100vh;
    height: calc(100vh - -332px);
  } */
  .home-sec-1 .home-img img {
    width: 100%;
  }
  .home-sec-1 .home-img .home-mobile-img {
    height: calc(100vh - 120px);
    width: 100%;
  }
  .header {
    padding-left: 19px !important;
    padding-top: 27px !important;
  }
  .slider-section-content {
    font-size: 12px;
  }
}


.home-img {
  background-color: #151b54;
  border: 1px solid #151b54;
  /* height: 100vh; */
}
.home-window-img {
  height: 60vh;
}

.home-img-wrap {
  left: 15% !important;
  top: 68% !important;
}

.home-img-wrap-2 {
  left: 15% !important;
  top: 75% !important;
}

.home-img-mobile-wrap {
  left: 19% !important;
  top: 41% !important;
}

.home-second-slider {
  left: 18% !important;
  top: 31% !important;
}

.home-sec-1 .slick-next {
  right: 30px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 2;
  padding-left: 1px !important ;
  padding-top: 4px !important;
  top: 45%;
}
.home-sec-1 .slick-prev {
  left: 12px !important;
  width: 40px !important;
  height: 40px !important;
  z-index: 2;
  padding-left: 1px !important;
  padding-top: 4px !important;
  top: 45%;
}

.slick-prev:before {
  content: none !important;
}

.slick-next:before {
  content: none !important;
}

/* .home-sec-1 {
  height: calc(100vh - -42px);
} */
.home-sec-1 .home-img img {
  /* height: calc(100vh - -60px); */
  width: 100%;
  border: 1px solid #151b54;
}

/* section 2 */

.section-2 {
  flex-direction: column;
}

.home-sec-2 {
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.home_boxes .sec-2-1 {
  height: 350px;
  display: block;
  margin: -90px 10px 80px !important;
  border-radius: 0;
  width: 300px;
  padding: 15px;
  border: 5px solid #fff;
  box-shadow: 1px 2px 20px #eee;
}
.home_boxes h5 {
  margin-top: 15px;
  font-size: 20px !important;
  color: #525460;
}
.home_boxes p {
  font-size: 13px !important;
  color: #000000;
}

.slider-section-content {
  color: #ffffff;
  /* font-size: 26px; */
}
.slider-section-content-1 {
  font-family: Almarai;
  font-size: 64px;
  font-weight: 800;
  line-height: 87.13px;
  text-align: left;
}

.slider-section-content-2 {
  font-family: Almarai;
  font-size: 30px;
  font-weight: 300;
  line-height: 33.28px;
  text-align: left;
}
.slider-section-button-wrapper {
  display: flex;
  align-items: center;
  width: 437px;
  height: 60.11px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
  margin-top: 100px;
}

.slider-section-button {
  font-family: Almarai;
  font-size: 24px;
  font-weight: 700;
  line-height: 50.47px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #151b54;
  border: unset;
  cursor: pointer;
}
.slider-section-mobile-button {
  font-family: Almarai;
  font-size: 15px;
  font-weight: 700;
  line-height: 50.47px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #151b54;
  border: unset;
}
.marker {
  width: 27.23px !important;
  height: 33.81px !important;
}

.slider-img-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.slider-section-2-button-wrapper {
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 242.98px; */
  height: 50px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
}

.slider-section-1-mobile-button-wrapper d-none {
  position: absolute;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  gap: 10px;
  margin-left: 25px;
}
.slider-section-3-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
  margin-top: 30px;
}
.slider-section-3 {
  padding-left: 97px;
  padding-top: 30px;
}
.slider-section-button-wrapper-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 259px;
  height: 35px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
}

.slider-section-content-1-responsive {
  font-family: Almarai;
  font-size: 40px;
  font-weight: 800;
  line-height: 44.38px;
  text-align: left;
}
.slider-section-content-2-responsive {
  font-family: Almarai;
  font-size: 18px;
  font-weight: 300;
}
.slider-section-button-responsive {
  font-family: Almarai;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #151b54;
}
.marker-responsive {
  height: 15px !important;
  width: 15px !important;
}

.slider-section-2-button-wrapper-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  padding: 20px;
  gap: 10px;
  margin-top: 20px;
}
.slider-section-content-3-responsive {
  font-family: Almarai;
  font-size: 40px !important;
  font-weight: 800;
}
.slider-section-3-button-wrapper-responsive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  gap: 10px;
}
@media only screen and (max-width: 768px) {
  .home-slider-resp {
    width: 100% !important;
    height: 50vh;
  }
  /* .home-img {
    height: 100vh;
  } */
  /* .home-sec-1 {
    height: 100vh;
    height: calc(100vh - -332px);
  } */
  .home-sec-1 .home-img img {
    width: 100%;
  }
  .home-sec-1 .home-img .home-mobile-img {
    height: calc(100vh - 120px);
    width: 100%;
  }
  .header {
    padding-left: 19px !important;
    padding-top: 27px !important;
  }
  .slider-section-content {
    font-size: 12px;
  }
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 10px;
}

.photo-item {
  width: 100%;
  height: 200px; /* Adjust this to control the height of the images */
  overflow: hidden;
}

.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the images cover the container nicely */
}

