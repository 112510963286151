.space-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 73px;
  z-index: 9999;
  position: relative;
}

.space {
  font-family: "Poppins" !important;
  font-size: 50px;
  font-weight: 600;
  color: #000000;
  line-height: 1;
}

.container2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 85px;
}

.box2 {
  width: 320px;
  /* height: 417px; */
  border-radius: 10px;
  background-color: #151b54;
  padding: 20px;
  text-align: center;
}

.text2-co {
  font-size: 30px;
  font-weight: 600;
}

.text2-bhk {
  font-size: 57.72px;
  font-weight: 700;
}

.text2-sz {
  font-family: Almarai;
  font-size: 25.52px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 15px;
}

.text2-sq {
  font-family: Almarai;
  font-size: 23.71px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 24px;
}

.get-price-btn {
  background: linear-gradient(45deg,
      #e6b0a2 0%,
      #c39ea0 33.33%,
      #b9778f 66.67%,
      #9c640c 100%);
  border: unset;
  width: 160px;
  height: 45px;
  border-radius: 5px;
  margin-top: 55px;
}

@media (max-width: 768px) {

  /* .space-wrapper {
    margin-top: -77px;
  } */
  .space {
    font-family: Almarai;
    font-size: 36px;
    font-weight: 700;
    line-height: 1;
  }

  .container2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 40px;
  }
}