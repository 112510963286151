.map-title {
    line-height: 1;
    font-size: 30px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 50px;
    margin-top: 50px;
}

.btn-explore {
    border: 1px solid #151b54;
    background-color: #ffffff;
    border-radius: 50px;
    width: 250px;
    height: 50px;
}

.text-span-bg {
    font-family: Almarai;
    font-size: 24px;
    font-weight: 700;
    color: #151b54;
    margin-top: 20px;
}

.image-container-map {
    margin: 0 20px;
}

.image-container-map img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.5s ease;
    /* Add transition effect */
}

.image-container-map img.active {
    transform: translateX(-50px);
    /* Adjust the distance the image moves */
}

.arrow {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.left {
    border-right: 20px solid #151b54;
    margin-right: 10px;
    cursor: pointer;
}

.right {
    border-left: 20px solid #151b54;
    margin-left: 10px;
    cursor: pointer;
}

.span-text {
    font-family: Almarai;
    font-size: 24px;
    font-weight: 700;
    line-height: 22.3px;
    text-align: center;
    color: #151b54;
}

@media only screen and (max-width: 768px) {
    .btn-explore {
        margin-top: 23px;
        margin-bottom: 23px;
    }

    .map-content {
        margin-top: 30px;
        margin-bottom: 50px;
    }
}