.btn {
  border-radius: 0px 0px 1.043px 1.043px !important;
  border: 1.043px solid var(--2, #e5d4c7) !important;
  background-color: var(--primary-color) !important;
}

.dropdown-mobile-view {
  display: none;
}

.dropdown-web-view {
  display: flex;
}

.perfectly-designed-homes-wrapper {
  background-color: #151b54;
  margin-top: 180px;
}
.heading {
  font-family: Almarai;
  font-size: 60px;
  font-weight: 700;
  color: #ffffff;
}
.button-class {
  width: 150px !important;
  height: 50px;
  gap: 4.84px;
  border-radius: 5px;
  border: unset;
}

.dropdown-web-view {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.know-more-btn {
  background: linear-gradient(
    45deg,
    #e6b0a2 0%,
    #c39ea0 33.33%,
    #b9778f 66.67%,
    #9c640c 100%
  );
  border: unset;
  width: 190px;
  height: 50px;
  border-radius: 5px;
}
.custom-tag {
  margin-top: 80px;
  margin-bottom: 51px;
}
@media (max-width: 768px) {
  .perfectly-designed-homes-wrapper {
    margin-top: 50px;
  }
  .custom-tag {
    margin-top: unset;
    margin-bottom: unset;
  }
}

@media (max-width: 992px) {
  .dropdown-mobile-view {
    display: block;
  }

  .dropdown-web-view {
    display: none;
  }
  .heading {
    font-size: 44px;
  }
}
