.sec_title_about {
  font-size: 50px;
  font-weight: 500;
  color: #000000 !important;
}

.fq_font-2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.97px;
}

.accordion-body {
  font-size: 16px;
  padding-left: 74px !important;
  border-bottom: 0 !important;
}
.acc-header-custom {
  gap: 30px;
}
.acc-header-custom {
  /* height: 96px; */
  display: flex;
}
.sec_subtitle {
  font-family: "Almarai";
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 17.08px;
  font-weight: 400;
  line-height: 25.63px;
  letter-spacing: 0.02em;
}

.accordion {
  --bs-accordion-btn-icon: url("../../images/add-icon-img.svg") !important;
  --bs-accordion-btn-active-icon: url("../../images/add-round.svg") !important;
  --bs-accordion-active-color: unset !important;
  --bs-accordion-active-bg: unset !important;
  --bs-accordion-border-radius: unset !important;
  --bs-accordion-border-color: unset !important;
  --bs-accordion-inner-border-radius: unset !important;

  /* Apply filter to reset colorization */
  --bs-accordion-icon-filter: brightness(100%);
  &:focus-visible {
    outline: none;
    box-shadow: unset;
  }
}
.accordion-button.collapsed {
  border-bottom: 1px solid #ccc;
}

.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom: none;
}
.accordion-button:focus {
  box-shadow: unset;
}
.map-div-custom {
  margin-top: 50px;
}
@media only screen and (max-width: 768px) {
  .sec_title_about {
    font-size: 36px;
  }
  .map-div-custom {
    margin-top: 43px;
  }
  .sec_title_about{
    font-size: 25px;
  }
}
