.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Custom styles */
.brand-logo {
  width: 200px;
  height: 80px;
  padding: 20px 8px;
}

.btn-gradient {
  color: #ffffff !important;
  background: linear-gradient(180deg, #D58F0A 0%, #CF5700 100%) !important;
  border: 0;
  outline: none;
  border-radius: 5px !important;
}

.btn-border-radius {
  border-radius: 5px !important;
}

.h480 {
  height: 480px;
}

.btn-outline-dark {
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
}

.site-plan {
  width: 100%;
}

.btn-outline-gradient {
  background-color: white;
  color: #cf5a01;
  text-decoration: none;
}

.btn-outline-gradient:hover,
.btn-outline-gradient.active {
  background: linear-gradient(to top, #cf5a01 0%, #da9c29 100%);
  color: #fff !important;
  transition: .25s ease-in-out;
}

.fs-60 {
  font-size: 60px !important;
}

.building-img {
  background: url("images/hero-section-left.png") no-repeat;
  width: 450px;
  height: 50vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-position-y: bottom;

}

.btn-gradient-outline {
  color: #cf5a01;
  font-size: 22px;
  font-weight: 600;
}

.text-orange {
  color: #cf5a01;
}

.text-sm {
  font-size: 12px;
}

.text-md{
  font-size: 14px;
}
.border-warning {
  border: 1px solid #cf5a01;
}

@media only screen and (max-width: 768px) {
  .warrior-sub-text {
    font-size: 12px;
    font-weight: 600;
  }

  .acc-header-custom {
    max-height: 100% !important;
  }
}

.map-wrapper {
  padding-left: 0;
}

@media only screen and (max-width: 768px) {
  .map-wrapper {
    padding-left: unset !important;
  }

  .virtue-logo-lg {
    width: 200px !important;
  }
}

.virtue-logo-lg {
  width: 300px;
}

/* .accordion-button::after {
  content: '';
  background-image: url('images/_plus.svg') !important;
} */

.custom-arrow {
  border: solid #cf5a01;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.custom-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  color: #fff;
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.header.hidden {
  /* transform: translateY(-100%); */
}

.header.visible {
  /* transform: translateY(0); */
}


.font-brandon {
  font-family: "Darker Grotesque" !important;
}

.text-gray-600 {
  color: #52545f !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.text-gray-700 {
  color: #2e3234 !important;
}

@media only screen and (max-width: 768px) {
  .warrior-header {
    font-size: 50px;
    padding-bottom: 15px;
  }
}

.accordion-button {
  border: none !important;
}

.accordion-button {
  background-color: transparent !important;
}

.accordion-item {
  background-color: #f8f9fa !important;
  margin-bottom: 15px !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-body {
  font-weight: 400;
  font-size: 14px;
  padding-top: 0 !important;
  color: #5a5364 !important;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.grid-container {
  display: grid;
  gap: 10px;
  padding: 10px;
}

/* Laptop screens: 5 cards per row */
@media (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(5, 240px);
    justify-content: center;
  }
}

/* Tablet screens: 4 cards per row */
@media (min-width: 768px) and (max-width: 1290px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Mobile screens: 2 cards per row */


.icon-size-sm {
  width: 60px;
  height: 60px;
}

.units-heading {
  font-size: 60px;
}

.units-measure {
  padding-bottom: 15px;
}

@media (max-width: 767px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .units-heading {
    font-size: 40px;
  }

  .units-measure {
    padding-bottom: 5px;
  }
}

@media (max-width: 390px) {
  .warrior-img-container {
    gap: 40px;
  }

  .units-heading {
    font-size: 30px;
  }

  .text-whitefield {
    font-size: 20px;
  }
}

body {
  overflow-y: auto;
  overflow-x: hidden;
}
.slick-slider{
  padding-bottom: 60px;
}