/* WEB VIEW  */
.image-wrapper {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.overlay:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay .logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
}

.overlay-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
  text-align: center;
  white-space: pre-line;
}

.overlay:hover .overlay-text {
  opacity: 1; /* Visible on hover */
}

.overlay:hover .logo {
  opacity: 0; /* Hide logo on hover */
}
.geometric-image {
  cursor: pointer;
}
/* ENDS HERE  */

.main-geometric {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 88px 0 0 0;
}

.custom-slider {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.image-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.image-container img {
  display: block;
  max-width: 350px;
}

.sub-img-content {
  position: relative;
  bottom: 406px;
  display: flex;
  justify-content: space-between;
  /* margin: 0 155px; */
  transition: transform 0.5s ease;
}

.geo-metric-btn-group {
  display: flex;
  justify-content: space-between;
  position: relative;
  bottom: 345px;
  margin: 0 12px;
}

button {
  background-color: unset;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .main-geometric {
    height: 100vh;
    margin: unset;
  }
}

@media only screen and (max-width: 569px) {
  /* .image-container img {
    width: 100%;
    max-width: 100%;
  } */
  .geo-metric-btn-group {
    margin: unset;
  }
  .sub-img-content {
    margin: 0 auto;
  }
  .main-geometric {
    height: 100vh;
    margin: unset;
  }
}
@media only screen and (min-width: 1400px) {
  .overlay:hover {
    height: 755px !important;
  }
}
