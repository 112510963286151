.main-heart-section {
  display: flex;
  margin-top: 40px;
  width: 100%;
}

.heart-header {
  font-family: Almarai;
  font-size: 64px;
  font-weight: 700;
  line-height: 65.57px;
  text-align: left;
  color: #181d24;
}

.heart-sub-text {
  /* width: 467.18px; */
  /* font-family: Almarai; */
  font-size: 16.39px;
  font-weight: 400;
  line-height: 29.14px;
  text-align: left;
  color: #181d24;
  gap: 12px;
}

.btn-explore-more {
  width: 269px;
  height: 59px;
  padding: 14px;
  border: 0;
  border-radius: 8px;
  background: #151b54;
  color: #fff;
}

.map-img {
  width: 100%;
  /* height: 100vh; */
}

.north-bglr-main-div {
  margin: 107px 98px 0 98px !important;
}
.title-div {
  margin-top: 58px !important;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .heart-sub-text {
    width: 350px;
  }

  .north-bglr-main-div {
    margin: 45px !important;
  }
}

@media (max-width: 568px) {
  .heart-header {
    font-size: 36px;
    line-height: 38.2px;
  }

  .heart-sub-text {
    font-size: 15px;
    line-height: 26px;
  }

  .btn-explore-more {
    width: 156px;
    height: 40px;
    padding: 0;
  }

  .img-container {
    height: auto;
  }

  .map-img {
    height: 321px;
    width: 100%;
  }
  .north-bglr-main-div {
    margin: 45px !important;
  }
}

@media (min-width: 1600px) {
  .heart-header {
    font-size: 86px;
    line-height: 73.57px;
  }
  .heart-sub-text {
    width: 570px;
    font-size: 24.39px;
    line-height: 37.14px;
  }
}
